@use 'sass:map';
@use '@angular/material' as mat;
@use "@angular/material/core/theming/theming";
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/tab/mixins' as mdc-tab-mixins;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: if(map.get($config, background), map.get($config, background), ());

  @if($background) {
    $surface: map.get($background, surface);
    $surface1: if(map.get($background, surface1), map.get($background, surface1), $surface);

    .mdc-nav-tab {
      background-color: $surface;
    }

    .mat-mdc-tab, .mat-mdc-tab-link {
      @include mdc-tab-mixins.text-label-color(mdc-theme-color.$on-surface);
    }

    // Set segmented tabs tabs colors
    .mat-mdc-tab-group.tab-group--segmented {
      .mat-mdc-tab-header {
        background-color: $surface1;
      }

      // Set active tab background
      .mdc-tab.mdc-tab-indicator--active .mdc-tab-indicator__content {
        background-color: $surface;
      }
    }

  }
}

@mixin theme($theme-or-color-config) {
  $color: theming.get-color-config($theme-or-color-config);

  @if $color !=null {
    @include color($color);
  }
}
