@use "../abstracts" as abstr;

// Secondary navigation
.mdc-nav-tab {
  .mat-mdc-tab-nav-bar .mdc-tab {
    height: 116px;
    padding: 28px 5.5px 18px;
    min-width: 64px;

    &+.mdc-tab {
      margin-left: 24px;
    }

    &__content {
      height: 100%;
      width: 100%;
    }

    &__text-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      &-text {
        display: block;
        flex-shrink: 1;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__icon-wrapper {
      margin-bottom: 16px;
      height: 32px;
      padding: 4px 20px;
      position: relative;
    }
  }

  &--mob.mdc-nav-tab {
    &>* {
      width: 100%;
      flex: 1;
    }

    .mat-mdc-tab {
      &-list {
        display: flex;
        width: 100%;
      }

      &-links {
        display: flex;
        width: 100%;
      }
    }
  }

  &--mob.mdc-nav-tab .mdc-tab {
    padding: 12px 4.5px 16px;
    height: 80px;
    flex: 1;
    min-width: 64px;

    &+.mdc-tab {
      margin-left: 0;
    }

    &__icon-wrapper {
      margin-bottom: 4px;
    }

    &-indicator .mdc-tab-indicator__content--underline{
      border-top-width: 3px;
      align-self: flex-start;
    }
  }

  &--mob.mdc-nav-tab .mat-mdc-tab-links {
    padding: 0 16px;
  }
}

// Segmented tabs
.mat-mdc-tab-group.tab-group--segmented {
  &.mat-mdc-tab-group {
    width: 100%;
    align-items: flex-start;
    border-radius: 24px;
  }

  .mat-mdc-tab-header {
    border-radius: inherit;
    padding: 4px;
    width: 100%;
  }

  @include abstr.respond-above(sm) {

    &.mat-mdc-tab-group,
    .mat-mdc-tab-header {
      width: auto;
    }

    .mat-mdc-tab-label-container {
      flex-grow: 0;
    }

  }

  .mat-mdc-tab.mdc-tab {
    flex: 1;
    min-width: 0;
    height: 40px;
    padding: 10px 34px;

    .mdc-tab-indicator__content {
      border-radius: 24px;
    }

  }

  .mdc-tab {

    &__content {
      height: auto;
    }

    &-indicator {
      &__content {
        height: 100%;
        width: 100%;
        border: 0;
      }
    }

    &+.mdc-tab {
      margin-left: 4px;
    }

    &__ripple::before {
      background: transparent;
    }

  }
}
