@use 'sass:map';
@use "@angular/material/core/theming/theming";
@use "@material/button/button-filled-theme" as mdc-button-filled-theme;
@use "../base" as base;

@mixin _widget-btn-styles($foreground, $background) {
  @include mdc-button-filled-theme.theme((container-color: $background,
      label-text-color: $foreground,
    ));
}

@mixin color($config-or-theme) {
  $color: theming.get-color-config($config-or-theme);
  $palette: map.get($color, primary);
  $background: theming.get-color-from-palette($palette);
  $foreground: theming.get-color-from-palette($palette, default-contrast);
  $opacity: 0.56;

  @if type-of($background)==color {
    $background: rgba($background, $opacity);

    .copy-widget .copy-widget-btn.mat-mdc-unelevated-button {
      @include _widget-btn-styles($foreground, $background);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $color: theming.get-color-config($theme-or-color-config);

  @if $color !=null {
    @include color($color);
  }
}
