@use 'sass:map';
@use '@angular/material' as mat;
@use "@angular/material/core/theming/theming";

@use "../base" as base;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  $brand2-config: if(map.get($config, brand2), map.get($config, brand2), ());

  @include base.validate-brand-palette($brand2-config, base.$brand-palette-keys) {

    .mat-avatar {
      // Read the 500 hue from the primary color palette.
      background-color: mat.get-color-from-palette($brand2-config, default);
      color: mat.get-color-from-palette($brand2-config, default-contrast);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $color: theming.get-color-config($theme-or-color-config);

  @if $color !=null {
    @include color($color);
  }
}
