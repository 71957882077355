@import "styles/index";

html,body{
  margin: 0;
  height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}
