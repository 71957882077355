@use "sass:map";
@use "@material/button/button" as mdc-button;
@use "@material/button/button-theme" as mdc-button-theme;
@use "@material/button/button-text-theme" as mdc-button-text-theme;
@use "@material/button/button-filled-theme" as mdc-button-filled-theme;
@use "@material/button/button-protected-theme" as mdc-button-protected-theme;
@use "@material/button/button-outlined-theme" as mdc-button-outlined-theme;
@use "@angular/material/button/button-theme-private";
@use "@angular/material/core/mdc-helpers/mdc-helpers";
@use "@angular/material/core/theming/theming";

@use "../base" as base;

$_button-theme-variants: (
  "brand1",
  "brand2"
);

@mixin _button-variant($color) {
  @include mdc-button-text-theme.theme((label-text-color: $color,
    ));
}

@mixin _unelevated-button-variant($foreground, $background) {
  @include mdc-button-filled-theme.theme((container-color: $background,
      label-text-color: $foreground,
    ));
}

@mixin _raised-button-variant($foreground, $background) {
  @include mdc-button-protected-theme.theme((container-color: $background,
      label-text-color: $foreground,
    ));
}

@mixin _ripple-color($color, $color-light: null) {
  $color-light: if($color-light, $color-light, $color);
  --mat-mdc-button-persistent-ripple-color: #{$color};
  --mat-mdc-button-ripple-color: #{$color-light};
}

@mixin _outlined-button-variant($color) {
  @include mdc-button-outlined-theme.theme((label-text-color: $color,
    ));
}

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  @include mdc-helpers.using-mdc-theme($config) {

    @each $key,
    $palette in base.extract-brand-palette($config, $_button-theme-variants) {
      @include base.validate-brand-palette($palette, base.$brand-palette-keys) {

        $brand-color: map.get($palette, default);
        $on-brand-color: map.get($palette, default-contrast);
        $brand-color-light: map.get($palette, lighter);
        $on-brand-color-light: map.get($palette, lighter-contrast);

        // Default
        .mat-mdc-button.mat-#{$key} {
          @include _button-variant($brand-color);
        }

        // Elevated
        .mat-mdc-unelevated-button.mat-#{$key} {
          @include _unelevated-button-variant($on-brand-color, $brand-color);
        }

        // Raised
        .mat-mdc-raised-button.mat-#{$key} {
          @include _raised-button-variant($on-brand-color, $brand-color);
        }

        // Outlined
        .mat-mdc-outlined-button.mat-#{$key} {
          @include _outlined-button-variant($brand-color);
        }

        // Ripple colors
        .mat-mdc-button,
        .mat-mdc-outlined-button {
          &.mat-#{$key} {
            @include _ripple-color($brand-color, $brand-color-light);
          }
        }

        .mat-mdc-raised-button,
        .mat-mdc-unelevated-button {
          &.mat-#{$key} {
            @include _ripple-color($on-brand-color, $on-brand-color-light);
          }
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $color: theming.get-color-config($theme-or-color-config);

  @if $color !=null {
    @include color($color);
  }
}
