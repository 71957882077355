@font-face {
    font-family: 'Client-Portal-Icons';
    src:  url('../../assets/fonts/Client-Portal-Icons.eot?6x70f1');
    src:  url('../../assets/fonts/Client-Portal-Icons.eot?6x70f1#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Client-Portal-Icons.ttf?6x70f1') format('truetype'),
      url('../../assets/fonts/Client-Portal-Icons.woff?6x70f1') format('woff'),
      url('../../assets/fonts/Client-Portal-Icons.svg?6x70f1#Client-Portal-Icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="cp-icon-"], [class*=" cp-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Client-Portal-Icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .cp-icon-facebook:before {
    content: "\e900";
  }
  .cp-icon-instagram:before {
    content: "\e901";
  }
  .cp-icon-pinterest:before {
    content: "\e902";
  }
  .cp-icon-twitter:before {
    content: "\e903";
  }
  .cp-icon-youtube:before {
    content: "\e904";
  }
  