@use "sass:map";
@use "variables" as *;

// Mixin to generate media queries
// @param {string} $breakpoint - The name of the breakpoint (e.g., sm, md, lg)
@mixin respond-above($breakpoint) {

    // If the breakpoint exists in the map.
    @if map.has-key($breakpoints, $breakpoint) {

      // Get the breakpoint value.
      $breakpoint-value: map.get($breakpoints, $breakpoint);

      // Write the media query.
      @media (min-width: $breakpoint-value) {
        @content;
      }

      // If the breakpoint doesn't exist in the map.
    }

    @else {

      // Log a warning.
      @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
  }
