@use '@angular/material' as mat;

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  // Set default body font style.
  body {
    @include mat.typography-level($typography-config, 'body-1');
  }

  // Emit all typography styles.
  @include mat.typography-hierarchy($typography-config, ".mat");

}

@mixin theme($theme) {
  $typography-config: mat.get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}
