@use 'sass:map';
@use '@angular/material' as mat;
@use "@angular/material/core/theming/theming";

@use "../base" as base;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  $color-config: if(map.get($config, brand3), map.get($config, brand3), ());

  @include base.validate-brand-palette($color-config, base.$brand-palette-keys) {

    .footer {
      // Read the 500 hue from the primary color palette.
      background-color: mat.get-color-from-palette($color-config, default);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $color: theming.get-color-config($theme-or-color-config);

  @if $color !=null {
    @include color($color);
  }
}
